/** @format */

@font-face {
	font-family: "Circular-Medium";
	src: local("Circular"),
	url("./fonts/Circular/CircularStd-Medium.otf") format("opentype");
}

@font-face {
	font-family: "Circular-Book";
	src: local("Circular"),
	url("./fonts/Circular/CircularStd-Book.otf") format("opentype");
}

@font-face {
	font-family: "Circular-Light";
	src: local("Circular"),
	url("./fonts/Circular/CircularStd-Light.otf") format("opentype");
}

@font-face {
	font-family: "Lazydog";
	src: local("Lazydog"),
	url("./fonts/Lazydog/Lazydog.otf") format("opentype");
}


body {
	margin: 0;
	font-family: 'Circular-Book';
}

p{
	font-family: 'Circular-Light';
}

body.modal-open {
    overflow: hidden;
}

input[type='text'],
input[type='number'],
input[type='email'],
textarea {
	font-size: 16px;
}

#content.disable {
	overflow: hidden;
	position: fixed;
}
